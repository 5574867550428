import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DangerSourceImageActions, RiskCategoryActions } from '../../../store/actions';
import { AppState } from '../../../store/reducers';
import { DangerSourceImageSelectors } from '../../../store/selectors';

@Component({
  selector: 'lib-picture-gallery',
  templateUrl: './picture-gallery.component.html',
  styleUrls: ['./picture-gallery.component.scss'],
})
export class UnitOfProductionPictureGalleryComponent implements OnInit {
  categories$: Observable<any>;
  createForm: number | null = null;
  constructor(private _store: Store<AppState>) {}

  ngOnInit(): void {
    this.categories$ = this._store.pipe(select(DangerSourceImageSelectors.getAllForSelectedUnitOfProdution));
    this._store.dispatch(RiskCategoryActions.load({ loadTree: true }));
    this._store.dispatch(DangerSourceImageActions.loadForSelectedUnitOfProduction());
  }

  onCreateFormClick(e: MouseEvent, dangerSourceId: number) {
    e.preventDefault();

    this.createForm = dangerSourceId;
  }
}
